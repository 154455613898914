import { acceptHMRUpdate, defineStore } from 'pinia'

const state = () => ({
  screenWidth: 1280,
  screenHeight: 720,
  hasEventListener: false,
})

export type MiscResizeState = ReturnType<typeof state>

export const useMiscResizeStore = defineStore('miscResize', {
  state: (): MiscResizeState => ({ ...state() }),
  actions: {
    SET_SCREEN_SIZE({ sizeX, sizeY }: { sizeX: number; sizeY: number }) {
      this.screenHeight = sizeY
      this.screenWidth = sizeX
    },
    SET_HAS_EVENT_LISTENER() {
      this.hasEventListener = true
    },
    SETUP_EVENT_LISTENERS(): void {
      if (import.meta.client && !this.hasEventListener) {
        window.addEventListener('resize', () => {
          this.COMPUTE_AND_SET_SCREEN_SIZE()
        })
        this.COMPUTE_AND_SET_SCREEN_SIZE()
      }
    },
    COMPUTE_AND_SET_SCREEN_SIZE() {
      if (
        this.screenHeight !== window.innerHeight ||
        this.screenWidth !== window.innerWidth
      ) {
        this.SET_SCREEN_SIZE({
          sizeX: window.innerWidth,
          sizeY: window.innerHeight,
        })
      }

      if (!this.hasEventListener) this.SET_HAS_EVENT_LISTENER()
    },
  },
  getters: {
    SCREEN_WIDTH(state) {
      return state.screenWidth
    },
    SCREEN_HEIGHT(state) {
      return state.screenHeight
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMiscResizeStore, import.meta.hot))
